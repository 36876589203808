.highlights {
    display: flex;
    flex-direction: column;
    margin: 0 0 ($default-block-margin - $default-margin);

    @include media-breakpoint-down(sm) {
        margin-left: -20px;
        margin-right: -20px;
    }

    &__title {
        text-align: center;
    }

    &__content {
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        text-align: center;
    }
}

.highlight {
    $self: &;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    margin-bottom: $default-block-margin - $default-margin;

    @include media-breakpoint-down(sm) {
        flex-direction: column;
    }

    .date-label {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 9;
    }

    &__image {
        @include make-col(6);
        margin-bottom: $default-margin;
        position: relative;

        img {
            border-radius: 20px;
        }

        @include media-breakpoint-down(sm) {
            @include make-col(12);

            img {
                max-height: 350px;
            }
        }
    }

    &__content {
        @include make-col(6);
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 0 55px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
            padding: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .richtext {
            margin: 0 0 30px;

            + .read-more {
                margin-top: -30px;
            }
        }
    }

    &--reversed {
        flex-direction: row-reverse;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }

        #{ $self }__content {
            padding: 0 50px;

            @include media-breakpoint-down(sm) {
                @include make-col(12);
                padding: 0;
            }
        }
    }

    &--video {
        flex-direction: column;

        #{ $self }__image {
            @include make-col(12);
            margin: 0;
            border-radius: 20px;

            img {
                border-radius: 20px;
            }

            .video {
                &::before {
                    @include icon(play, $color-white, 60px, 60px);
                    content: '';
                    position: absolute;
                    width: 60px;
                    height: 60px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    &--text {
        align-items: initial;
        flex-direction: column;

        #{ $self }__content {
            @include make-col(12);
            padding: 0;
        }
    }
}

.dupli-rt1c .highlights {
    padding: 0 30px;

    .highlight__image {

        @include media-breakpoint-down(sm) {

            img {
                max-height: 100%;
            }
        }
    }
}


.highlights-overview {
    $self: &;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;

    &__item {
        @include make-col(6);
        padding: 0 30px;

        @include media-breakpoint-down(sm) {
            @include make-col(12);
        }
    }

    &--row {
        #{ $self }__item {
            @include make-col(12);
            display: flex;
        }
    }

    &--three-columns {
        #{ $self }__item {
            @include make-col(4);

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }

    &--stores {
        margin: 0 -15px;

        #{ $self }__item {
            @include make-col(4);
            padding: 0 10px;

            @include media-breakpoint-down(md) {
                @include make-col(6);
            }

            @include media-breakpoint-down(sm) {
                @include make-col(12);
            }
        }
    }

    &--two-columns,
    &--three-columns {
        #{ $self }__item {
            margin: 0 0 ($default-block-margin - $default-margin);

            .highlight:not(.highlight--small) {
                height: 100%;
                margin: 0;

                .richtext {
                    + p {
                        margin-top: auto;
                    }
                }
            }
        }
    }
}

html :not(.fp-enabled) .block--highlights {
    margin: 0;
    padding: 40px 0;
}


