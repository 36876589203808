.list {
    padding: 0;
    margin: 0 0 $default-margin;
    list-style: none;

    li {
        display: block;
    }

    &--office {
        display: block;
        border-bottom: 1px solid $color-white;
        max-width: 100%;
        padding-bottom: 25px;
        flex-grow: 1;
        width: 100%;

        @include media-breakpoint-down(sm) {
            width: 100%;
            min-width: unset;
        }

        li {
            display: flex;
            align-items: center;
            padding: 2px 0;

            span {
                + span {
                    margin-left: 5px;
                }
            }
        }
    }

    &--naw {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        li {
            flex: 0 0 33.33%;
            max-width: 33.33%;
            padding: 0 10px;
            margin: 0 0 $default-margin;

            @include media-breakpoint-down(md) {
                flex: 0 0 50%;
                max-width: 50%;
            }

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            a,
            button {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
                color: $color-white;
                text-decoration: none;
                text-align: left;
                padding: 0;

                p {
                    flex: 1;
                    line-height: 20px;
                    margin: 0;
                }

                .icon {
                    flex: 0 0 30px;
                    max-width: 30px;
                    width: 30px;
                    height: 40px;
                    margin: 0 40px 0 0;

                    &--phone {
                        @include icon(phone, $color-white, 30x, 30px);
                    }

                    &--messenger {
                        @include icon(messenger, $color-white, 30px, 30px);
                    }

                    &--form {
                        @include icon(form, $color-white, 30px, 30px);
                    }

                    &--marker {
                        @include icon(marker, $color-white, 38px, 38px);
                    }

                    &--chat {
                        @include icon(chat, $color-white, 30px, 30px);
                    }

                    &--globe {
                        @include icon(globe-small, $color-white, 30px, 30px);
                    }
                }

                &:hover,
                &:focus {
                    color: $color-red;
                    text-decoration: none;

                    .icon {
                        &--phone {
                            @include icon(phone, $color-red, 30px, 30px);
                        }

                        &--messenger {
                            @include icon(messenger, $color-red, 30px, 30px);
                        }

                        &--form {
                            @include icon(form, $color-red, 30px, 30px);
                        }

                        &--marker {
                            @include icon(marker, $color-red, 38px, 38px);
                        }

                        &--chat {
                            @include icon(chat, $color-red, 30px, 30px);
                        }

                        &--globe {
                            @include icon(globe-small, $color-red, 30px, 30px);
                        }
                    }
                }
            }
        }
    }

    &--sites {
        margin: 0;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 18px;
        grid-row-gap: 36px;

        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(2, 1fr);
        }

        h4 {
            font-size: 32px;
            line-height: 36px;
            font-weight: 900;
            margin-bottom: 15px;
        }

        li {
            a:not(.btn) {
                position: relative;
                display: flex;
                align-items: center;
                color: $color-white;
                text-decoration: none;
                padding-left: 30px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                background-color: transparent;
                font-weight: 500;

                &::after {
                    position: absolute;
                    margin-top: 4px;
                    left: 0;
                    display: block;
                    width: 24px;
                    height: 18px;
                    content: '';
                    background-repeat: no-repeat;
                    background-position: center;
                }

                &:hover,
                &:focus {
                    text-decoration: underline;
                }
            }

            &.de a::after {
                background-image: url('/assets/img/dc/flag/de.svg');
            }

            &.en a::after {
                background-image: url('/assets/img/dc/flag/gb.svg');
            }

            &.es a::after {
                background-image: url('/assets/img/dc/flag/es.svg');
            }

            &.fr a::after {
                background-image: url('/assets/img/dc/flag/fr.svg');
            }

            &.it a::after {
                background-image: url('/assets/img/dc/flag/it.svg');
            }

            &.nl a::after {
                background-image: url('/assets/img/dc/flag/nl.svg');
            }

            &.pl a::after {
                background-image: url('/assets/img/dc/flag/pl.svg');
            }
            
            &.fi a::after {
                background-image: url('/assets/img/dc/flag/fi.svg');
                background-size: contain;

            }
        }
    }


    &--footer {
        margin: 0 0 40px;

        li {
            display: block;

            a {
                display: block;
                font-size: 16px;
                line-height: 23px;
                color: $text-color;

                &:hover,
                &:focus {
                    color: $text-color;
                    text-decoration: underline;
                }
            }
        }
    }

    &--social-follow {
        display: flex;

        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
            justify-content: center;
            max-width: 100%;
        }

        li {
            a {
                position: relative;
                display: block;
                width: 31px;
                height: 31px;
                font-size: 0;
                border-radius: 50%;
                background-color: transparent;

                &::after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100%;
                    content: '';
                }
            }

            &.facebook {
                a {
                    &::after {
                        @include icon(facebook, $color-white, 20px, 20px);
                    }
                }
            }

            &.twitter {
                a {
                    &::after {
                        @include icon(twitter, $color-white, 20px, 20px);
                    }
                }
            }

            &.youtube {
                a {
                    &::after {
                        @include icon(youtube, $color-white, 20px, 20px);
                    }
                }
            }

            &.linkedin {
                a {
                    &::after {
                        @include icon(linkedin, $color-white, 20px, 20px);
                    }
                }
            }

            &.instagram {
                a {
                    &::after {
                        @include icon(instagram, $color-white, 20px, 20px);
                    }
                }
            }

            &.pinterest {
                a {
                    &::after {
                        @include icon(pinterest, $color-white, 20px, 20px);
                    }
                }
            }
        }
    }

    &--social-share {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 10px;

        li {
            flex: 0 0 50%;
            max-width: 50%;
            margin: 0 0 8px;

            @include media-breakpoint-down(sm) {
                flex: 0 0 100%;
                max-width: 100%;
            }

            a {
                position: relative;
                display: block;
                color: $text-color;
                font-size: 14px;
                line-height: 22px;
                padding: 5px 15px 5px 36px;

                &:hover,
                &:focus {
                    text-decoration: underline;
                }

                &::before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: block;
                    width: 32px;
                    height: 32px;
                    content: '';
                }
            }

            &.facebook {
                a {
                    &::before {
                        @include icon(facebook, $text-color, 20px, 20px);
                    }
                }
            }

            &.twitter {
                a {
                    &::before {
                        @include icon(twitter, $text-color, 20px, 20px);
                    }
                }
            }

            &.youtube {
                a {
                    &::before {
                        @include icon(youtube, $text-color, 20px, 20px);
                    }
                }
            }

            &.linkedin {
                a {
                    &::before {
                        @include icon(linkedin, $text-color, 20px, 20px);
                    }
                }
            }

            &.instagram {
                a {
                    &::before {
                        @include icon(instagram, $text-color, 20px, 20px);
                    }
                }
            }

            &.pinterest {
                a {
                    &::before {
                        @include icon(pinterest, $text-color, 20px, 20px);
                    }
                }
            }

            &.whatsapp {
                a {
                    &::before {
                        @include icon(whatsapp, $text-color, 20px, 20px);
                    }
                }
            }

            &.email {
                a {
                    &::before {
                        @include icon(email, $text-color, 20px, 20px);
                    }
                }
            }
        }
    }
}
