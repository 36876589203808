.block {
  &--products {
    overflow: hidden;
  }
}

.products {
  &__title {
    position: relative;
    text-align: center;
    margin-bottom: 54px;
    color: $text-color;
    z-index: 5;

    @include media-breakpoint-down(sm) {
      margin-bottom: 0;
    }

    .is-h2 {
      @include media-breakpoint-down(sm) {
        font-size: 37px;
        line-height: 32px;
        padding-top: 45px;
      }
    }
  }
}

html :not(.fp-enabled) .block {
  
  &--products, &--about, &--spraypaint, &--howtos {
    padding: 60px 0;
  }

  &--advantages {
    padding: 40px 0 80px 0;
  }

} 

html :not(.fp-enabled) #content > div > div:first-of-type .block, html :not(.fp-enabled) #content > div > div.block:first-of-type {
  // margin-top: 0 !important;
  padding: 200px 0 100px 0;
}

.product {
  display: block;
  text-align: center;
  background-color: $color-white;
  border-radius: 20px;
  padding: 40px 10px 32px;
  text-decoration: none;
  transition: color .3s;
  max-height: 567px;

  @include media-breakpoint-down(sm) {
    padding: 14px 10px 26px;
    max-height: 432px;
  }

  &__image {
    margin-bottom: 12px;

    picture {
      margin: 0;
    }
  }

  .is-h4 {
    font-weight: 700;
    font-size: 21px;
    line-height: 25px;
    // color: $color-orange;
    transition: color .3s;
    margin: 0;

    @include media-breakpoint-down(sm) {
      min-height: 75px;
    }
  }

  &:hover {
    text-decoration: none;

    .is-h4 {
      // color: $color-blue;
      opacity: 0.6;
    }
  }
}

.home .product .is-h4 {
  @include media-breakpoint-down(sm) {
    min-height: 0 !important;
  }
}

.products-slider {
  padding-bottom: 70px;

  @include media-breakpoint-down(sm) {
    padding-bottom: 15px;
  }

  &__item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
    padding: 0 54px;

    @include media-breakpoint-down(lg) {
      padding: 0 30px;
    }

    @include media-breakpoint-down(md) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include media-breakpoint-down(sm) {
      flex: 0 0 61%;
      max-width: 61%;
      padding: 0 18px;
    }
  }
}

.swiper {
  &--products-slider {
    overflow: initial;
  }
}