.footer {
    position: relative;
    flex-shrink: 0;
    background-color: $color-purple;

    &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 40px;

        @include media-breakpoint-down(md) {
            align-items: center;
            justify-content: center;
            padding-bottom: 80px;
        }
    }

    &__navigation {
        display: flex;
        align-items: center;
    }

    .copyright {
        display: inline-block;
        font-weight: 600;
        margin-right: 18px;

        @include media-breakpoint-down(md) {
            margin: 0 0 10px;
        }
    }

    .logo {
        margin-bottom: 10px;
        margin-right: 10px;

        @include media-breakpoint-down(md) {
            margin: 0 0 50px;
        }
    }

    .list {
        margin: 0 auto 0 0;

        @include media-breakpoint-down(md) {
            margin-bottom: 10px;
        }
    }
}
