.custom-select {
    background-color: $color-white;
    position: relative;
    height: 40px;
    border-radius: 20px;
    overflow: hidden;

    &__icon,
    &__arrow {
        top: 0;
        position: absolute;
        height: 100%;
        pointer-events: none;
    }

    &__icon {
        left: 0;
        width: 50px;
    }

    &__arrow {
        @include icon(updown, $color-black, 7px, 14px);
        right: 15px;
        width: 20px;
    }

    select:not(.selectpicker) {
        // z-index: 2;
        color: $color-gray-dark;
        font-size: 16px;
        line-height: 40px;
        font-weight: 600;
        padding: 0 50px;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        border: 0;
        background-color: transparent;
        border-radius: 20px;
        outline: none;
        appearance: none;
        cursor: pointer;
    }

    /*
    &--segment,
    &--colors,
    &--inoutdoor,
    &--surface {
        box-shadow: 0 0 10px rgba(0,0,0,.353);
    }
    */

    &--inoutdoor & {
        &__icon {
            @include icon(indutdoor, $color-black, 32px, 22px);
        }
    }

    &--surface & {
        &__icon {
            @include icon(syrface, $color-black, 22px, 14px);
        }
    }

    &--colors & {
        &__icon {
            @include icon(colors, $color-black, 23px, 21px);
        }
    }

    &--article & {
        &__icon {
            @include icon(article, $color-black, 23px, 21px);
        }
    }

    &--volume & {
        &__icon {
            @include icon(spray, $color-black, 23px, 21px);
        }
    }

    &--segment & {
        &__icon {
            @include icon(spray, $color-black, 23px, 21px);
        }
    }

    &--glosstype & {
        &__icon {
            @include icon(glosstype, $color-white, 23px, 21px);
        }
    }
}