// Bootstrap settings
$container-max-widths: (
        'sm': 540px,
        'md': 720px,
        'lg': 960px,
        'xl': 1170px
) !default;

// Lightcase settings
$lightcase-custom: (
        'font-path': '/assets/fonts/',
        'breakpoint': 768px
) !default;

// Overall
$default-margin: 30px;
$default-block-margin: 64px;

$heading-font: 'arboria', sans-serif;
$text-font: 'arboria', sans-serif;

$text-size: 16px;
$line-height: 26px;

$color-white: #fff;
$color-black: #000;

$text-color: $color-white;

$color-red: #D8001B;
$color-red-dark: #8b0011;
$color-pink: #D40067;
$color-blue: #008FDB;
$color-blue-light: #008FDB;
$color-blue-dark: #004D99;
$color-green: #319332;
$color-green-light: #93B200;
$color-green-dark: #93B200;
$color-green-darker: #536500;
$color-purple: #79006c;
$color-purple-dark: #d40067;
$color-purple-darker: #79006c;
$color-yellow: #FFD300;
$color-yellow-dark: #f27f00;
$color-orange: #F27F00;
$color-orange-dark: #723b00;
$color-gray: #707070;
$color-gray-dark: #272727;
$color-gray-dark2: #282A2A;
$color-gray-light: #d1d1d1;
