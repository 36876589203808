.block {
    &--search {
        padding-top: 120px;

        .search-results {
            &--list {
                display: flex;
                flex-direction: column;
                margin: 0 0 20px;

                &__item,
                &__empty-item {
                    display: flex;
                    flex-direction: column;
                }

                h2 {
                    margin-bottom: 0;
                    font-size: 42px;
                    line-height: 44px;
                }

                .url {
                    display: block;
                    margin-bottom: 20px;
                    font-size: 14px;
                    line-height: 20px;
                    font-style: italic;
                }
            }
        }

        .search-result {
            text-decoration: none;
            margin: 0 0 10px;

            &:hover,
            &:focus {
                color: $text-color;
                text-decoration: none;
            }

            &-item {
                display: flex;
                margin-bottom: $default-margin;
                color: $text-color;

                @include media-breakpoint-down(sm) {
                    display: block;
                }

                &__image {
                    flex-shrink: 0;
                    margin-right: $default-margin;
                    width: 150px;

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                        margin: 0 0 $default-margin;
                        text-align: center;
                    }
                }
            }
        }

        .pagination {
            margin-bottom: 50px;
        }
    }
}
