.blocktheme {
    &--red {
        background-color: $color-red;

        /**.hero-main:not(.hero-main--video):not(.hero-main--regular) {
            &::before {
                z-index: 2;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                clip-path: polygon(0 0, 49% 0, 59% 72%, 0 100%);
                background-color: $color-red;

                @include media-breakpoint-down(md) {
                clip-path: polygon(0 0, 65% 0, 70% 47%, 0 80%);
                }

                @include media-breakpoint-down(sm) {
                clip-path: polygon(0 0, 65% 0, 91% 47%, 0 59%);
                }
            }
        }**/

        .advantages *, .products__slider a * {
            color: $color-red-dark;
        }

        .btn {
            background-color: $color-red-dark;
        }
    }

    &--orange {
        background-color: $color-orange;

        /**.hero-main:not(.hero-main--video):not(.hero-main--regular) {
            &::before {
                z-index: 2;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                clip-path: polygon(0 0, 49% 0, 59% 72%, 0 100%);
                background-color: $color-orange;

                @include media-breakpoint-down(md) {
                clip-path: polygon(0 0, 65% 0, 70% 47%, 0 80%);
                }

                @include media-breakpoint-down(sm) {
                clip-path: polygon(0 0, 65% 0, 91% 47%, 0 59%);
                }
            }
        }**/

        .advantages *, .products__slider a * {
            color: $color-orange-dark;
        }

        .btn {
            background-color: $color-orange-dark;
        }
    }

    &--blue {
        background-color: $color-blue;

        /**.hero-main:not(.hero-main--video):not(.hero-main--regular) {
            &::before {
                z-index: 2;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                clip-path: polygon(0 0, 49% 0, 59% 72%, 0 100%);
                background-color: $color-blue;

                @include media-breakpoint-down(md) {
                clip-path: polygon(0 0, 65% 0, 70% 47%, 0 80%);
                }

                @include media-breakpoint-down(sm) {
                clip-path: polygon(0 0, 65% 0, 91% 47%, 0 59%);
                }
            }
        }**/

        .advantages *, .products__slider a * {
            color: $color-blue-dark
        }

        .btn {
            background-color: #004D99;
        }
    }

    &--yellow {
        background-color: $color-yellow;

        /**.hero-main:not(.hero-main--video):not(.hero-main--regular) {
            &::before {
                z-index: 2;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                clip-path: polygon(0 0, 49% 0, 59% 72%, 0 100%);
                background-color: $color-yellow;

                @include media-breakpoint-down(md) {
                clip-path: polygon(0 0, 65% 0, 70% 47%, 0 80%);
                }

                @include media-breakpoint-down(sm) {
                clip-path: polygon(0 0, 65% 0, 91% 47%, 0 59%);
                }
            }
        }**/

        .advantages *, .products__slider a * {
            color: $color-yellow-dark;
        }

        .btn {
            background-color: $color-yellow-dark;
        } 
    }

    &--lightgreen {
        background-color: $color-green-light;

        /**.hero-main:not(.hero-main--video):not(.hero-main--regular) {
            &::before {
                z-index: 2;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                clip-path: polygon(0 0, 49% 0, 59% 72%, 0 100%);
                background-color: $color-green-light;

                @include media-breakpoint-down(md) {
                clip-path: polygon(0 0, 65% 0, 70% 47%, 0 80%);
                }

                @include media-breakpoint-down(sm) {
                clip-path: polygon(0 0, 65% 0, 91% 47%, 0 59%);
                }
            }
        }**/

        .advantages *, .products__slider a * {
            color: $color-green;
        }

        .btn {
            background-color: $color-green;
        }
    }

    &--darkgreen {
        background-color: $color-green-dark;

        /**.hero-main:not(.hero-main--video):not(.hero-main--regular) {
            &::before {
                z-index: 2;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                clip-path: polygon(0 0, 49% 0, 59% 72%, 0 100%);
                background-color: $color-green-dark;

                @include media-breakpoint-down(md) {
                clip-path: polygon(0 0, 65% 0, 70% 47%, 0 80%);
                }

                @include media-breakpoint-down(sm) {
                clip-path: polygon(0 0, 65% 0, 91% 47%, 0 59%);
                }
            }
        }**/

        .advantages *, .products__slider a * {
            color: $color-green-darker;
        }

        .btn {
            background-color: $color-green-darker;
        }
    }

    &--lightblue {
        background-color: $color-blue-light;

        /**.hero-main:not(.hero-main--video):not(.hero-main--regular) {
            &::before {
                z-index: 2;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                clip-path: polygon(0 0, 49% 0, 59% 72%, 0 100%);
                background-color: $color-blue-light;

                @include media-breakpoint-down(md) {
                clip-path: polygon(0 0, 65% 0, 70% 47%, 0 80%);
                }

                @include media-breakpoint-down(sm) {
                clip-path: polygon(0 0, 65% 0, 91% 47%, 0 59%);
                }
            }
        }**/

        .advantages *, .products__slider a * {
            color: $color-blue-dark;
        }

        .btn {
            background-color: $color-blue-dark;
        }
    }

    &--purple {
        background-color: $color-purple;

        /**.hero-main:not(.hero-main--video):not(.hero-main--regular) {
            &::before {
                z-index: 2;
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                clip-path: polygon(0 0, 49% 0, 59% 72%, 0 100%);
                background-color: $color-purple;

                @include media-breakpoint-down(md) {
                clip-path: polygon(0 0, 65% 0, 70% 47%, 0 80%);
                }

                @include media-breakpoint-down(sm) {
                clip-path: polygon(0 0, 65% 0, 91% 47%, 0 59%);
                }
            }
        }**/

        .advantages *, .products__slider a * {
            color: $color-purple-dark;
        }

        .btn {
            background-color: $color-purple-dark;
        }
    }
}

.block {
    &--color-purple {
        background-color: $color-purple;
    }
}
