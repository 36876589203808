.block {
  &--about {
    position: relative;

    @include media-breakpoint-down(md) {
      padding-bottom: 100px;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 230px;
    }

    &::after {
      @include icon(circle-bg, $color-white, 319px, 290px);
      position: absolute;
      bottom: 0;
      right: 0;
      width: 319px;
      height: 290px;
      content: '';

      @include media-breakpoint-down(sm) {
        @include icon(circle-bg-mobile, $color-white, 253px, 230px);
        // width: 253px;
        // height: 230px;
        background-position: 0%;
        height: 161px;
        width: 211px;
      }
    }
  }
}

.about {
  color: $color-white;

  .is-h1 {
    margin: 0;
  }

  &__title {
    margin-bottom: 45px;

    @include media-breakpoint-down(sm) {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }

  &__footer {
    margin-top: 56px;

    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }
  }

  .rich-text {
    max-width: 546px;

    ul {
      list-style-type: disc;
      padding-left: 20px;
    }

    ol {
      list-style-type: decimal;
      padding-left: 20px;
    }
  }
}