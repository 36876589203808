.pagination {
    &-list {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: center;
        margin: 0;

        .page-item {
            margin: 0 8px;
            padding: 0;

            @include media-breakpoint-down(sm) {
                margin: 0 2px;
            }

            &.active {
                .page-link {
                    font-weight: 600;
                    background-color: $color-pink;
                }
            }

            &.control {
                margin: 0;

                .page-link {
                    @include icon(arrow, $color-purple, 6px, 12px);
                    background-color: transparent;
                    overflow: hidden;
                    text-indent: -99999px;
                    width: 30px;

                    &:hover,
                    &:focus {
                        @include icon(arrow, $color-pink, 6px, 12px);
                        background-color: transparent;
                    }
                }

                &.prev {
                    .page-link {
                        transform: rotate(-180deg);
                    }
                }

                &.next {
                    .page-link {
                        transform: rotate(0);
                    }
                }
            }
        }

        .page-link {
            background-color: $color-purple;
            display: flex;
            width: 41px;
            height: 41px;
            color: $color-white;
            font-weight: 700;
            justify-content: center;
            line-height: 38px;
            border-radius: 50% !important;
            border: 0;
            padding: 0;
            margin: 0;
            transition: background-color .3s;

            &:not(span) {
                &:hover,
                &:focus {
                    background-color: $color-pink;
                }
            }
        }
    }
}
