.highlights-overview {
    $self: &;

    &--row {
        @include media-breakpoint-down(sm) {
            margin-left: 0;
            margin-right: 0;
        }

        #{ $self }__item {
            max-width: 33%;
            padding: 10px;

            @include media-breakpoint-down(md) {
                max-width: 100%;
            }

            .highlight {
                display: flex;
                flex-direction: column;

                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                }

                &__image {
                    display: block;
                    max-width: 100%;
                    margin: 0;

                    img {
                        width: 100%;
                        border-radius: 20px 20px 0 0;
                    }
                }

                &__content {
                    padding: 15px;
                    max-width: 100%;
                    background-color: $color-white;
                    border-radius: 0 0 20px 20px;

                    .is-h3 {
                        color: $color-black;
                        margin: 0;
                        font-size: 16px;
                        line-height: 26px;
                    }

                    p {
                        color: $color-black !important;
                        margin: 0;

                        &.blog-article-link {
                            display: none;
                        }

                        &:has(.btn) {
                            margin-top: auto;
                        }
                    }
                }
            }
        }
    }
}


html :not(.fp-enabled) #content > div > div.block--filter:first-of-type {
    padding: 200px 0 0 0;
}
