.fullpage-wrapper {
  .section {
    .wrapper {
      opacity: .3;
      transition: opacity .5s .3s;
    }

    &.active {
      .wrapper {
        opacity: 1;
      }
    }
  }

  .fp-auto-height .fp-overflow {
    overflow: visible;
  }

  .fp-overflow {
    height: 100%;

    .hero-main {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .block {
      height: 100%;
      display: flex;
      align-items: center;

      &:not(.block--hero-main) {
        @include media-breakpoint-down(xs) {
          padding: 50px 0;
          display: block;
          height: auto;
          min-height: 100%;
          max-height: 100%;
          overflow: hidden;
        }
      }

      &--about {
        @include media-breakpoint-down(sm) {
          padding-top: 90px;
        }
      }
    }
  }

  .fp-watermark {
    display: none;
  }
}
