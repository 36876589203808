.block {

}

.hero-main {
  height: 100vh;
  position: relative;

  .is-h1 {
    color: $color-white;
    font-size: 147px;
    line-height: 180px;
    margin-bottom: 60px;
    text-shadow: 0 0 10px rgba(0,0,0,.353);

    @include media-breakpoint-down(lg) {
      font-size: 180px;
      line-height: 200px;
    }

    @include media-breakpoint-down(md) {
      font-size: 140px;
      line-height: 160px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 72px;
      line-height: 80px;
      margin-bottom: 30px;
    }
  }

  &__bg {
    height: 100%;

    picture {
      height:100%;

      img {
        width: 100%;
        height:100%;
        object-fit: cover;
      }
    }

    @include media-breakpoint-down(sm) {
      top: 35px;
      left: 0;
      text-align: center;
    }
  }

  &__image {
    height: 100%;

    picture {
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__video {
    height: 100%;

    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
    padding-bottom: 80px;

    @media (min-height: 780px) {
      padding-bottom: 160px;
    }

    @media (min-height: 900px) {
      padding-bottom: 200px;
    }

    @media (min-height: 1100px) {
      padding-bottom: 260px;
    }
  }

  &--video {
    &::before {
      opacity: .65;
    }
  }
}
