.spraypaint {
    color: $text-color;
    position: relative;

    &__title {
        margin-bottom: 15px;
        text-align: center;

        @media (max-height: 800px) {
            margin-bottom: 0;

            .is-h2 {
                font-size: 26px;
                line-height: 26px;
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: center;
        margin-top: 44px;

        @media (max-height: 800px) {
            margin-top: 10px;
        }
    }

    .is-h2 {
        margin: 0;
    }

    .highlight {
        margin: 0 auto 40px;
        max-width: 922px;

        @media (max-height: 800px) {
            margin-bottom: 10px;

            &__image {
                margin: 40px auto 0;

                img {
                    height: 110px;
                }

                .video {
                    &::before {
                        width: 30px;
                        height: 30px;
                        background-size: contain;
                    }
                }
            }
        }
    }

    .rich-text {
        max-width: 546px;
        margin: 0 auto;

        @media (max-height: 800px) {
            max-width: unset !important;

            p {
                margin-bottom: 10px;
                line-height: 22px;
            }
        }
    }
}
