h1,
.is-h1 {
    font-family: $heading-font;
    font-size: 140px;
    line-height: 150px;
    font-weight: 900;
    text-transform: uppercase;

    @include media-breakpoint-down(lg) {
        font-size: 100px;
        line-height: 100px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 64px;
        line-height: 64px;
    }

    .special {
        color: $color-white;
        font-size: 52px;
        font-weight: 300;
    }
}

h2,
.is-h2 {
    font-family: $heading-font;
    font-weight: 700;
    font-size: 72px;
    line-height: 80px;

    @include media-breakpoint-down(sm) {
        font-size: 44px;
        line-height: 44px;
    }

    .special {
        color: $color-white;
        font-size: 32px;
        font-weight: 300;
    }
}

.fp-enabled h2 {
    @include media-breakpoint-down(sm) {
        font-size: 34px;
        line-height: 35px;
    }
}

.fp-enabled .creative__title h2 {
    @include media-breakpoint-down(sm) {
        line-height: 1;
        font-size: 30px;
    }
}

h3,
.is-h3 {
    font-family: $heading-font;
    font-weight: 700;
    font-size: 52px;
    line-height: 60px;

    @include media-breakpoint-down(md) {
        font-size: 42px;
        line-height: 42px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 28px;
        line-height: 28px;
    }

    .special {
        color: $color-white;
        font-size: 22px;
        font-weight: 300;
    }
}

h4,
.is-h4 {
    font-family: $heading-font;
    font-weight: 900;
    font-size: 32px;
    line-height: 36px;

    @include media-breakpoint-down(md) {
        font-size: 24px;
        line-height: 30px;
    }

    @include media-breakpoint-down(sm) {
        font-size: 16px;
        line-height: 20px;
    }
}

h5,
.is-h5 {
    font-family: $heading-font;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    margin: 0 0 $default-margin / 2;
}

h6,
.is-h6 {
    font-family: $heading-font;
    font-size: 14px;
    line-height: 28px;
    font-weight: 600;
    margin: 0 0 $default-margin / 2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    + .info {
        font-size: 14px;
        margin-top: -$default-margin;
    }
}