.swiper {
    margin: 0;
    overflow: hidden;
    position: static;

    .swiper-slide {
        height: auto;
    }

    .swiper-navigation {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-right: 18px;

        @include media-breakpoint-down(sm) {
            padding-right: 5px;
        }

        .swiper-button-next,
        .swiper-button-prev {
            @include icon(arrow, $text-color, 14px, 24px);
            display: block;
            width: 40px;
            height: 40px;
            cursor: pointer;

            &.swiper-button-disabled {
                cursor: default;
                opacity: .5;
            }
        }

        .swiper-button-prev {
            transform: rotate(-180deg);
        }
    }
}
