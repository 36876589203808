.mobile-nav {
  background-color: $color-white;
  color: $color-purple;
  font-size: 12px;
  line-height: 16px;
  font-weight: bold;
  position: fixed;
  bottom: 0;
  width: 100%;
  display: none;
  justify-content: center;
  align-items: flex-end;
  height: 60px;
  padding: 0 0 10px;
  z-index: 100;

  @include media-breakpoint-down(md) {
    display: flex;
  }

  &__item {
    position: relative;
    width: 100px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;

    &-icon {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 20px;
      left: 50%;
      border-radius: 50%;
      transform: translateX(-50%);
      transition: width .3s, height .3s;

      &.search {
        @include icon(search, $color-purple, 20px, 20px);
      }

      &.language {
        @include icon(globe, $color-purple, 20px, 20px);
      }

      &.find {
        @include icon(spray, $color-purple, 20px, 20px);
      }
    }

    &.active & {
      &-icon {
        background-color: $color-pink;
        width: 50px;
        height: 50px;

        &.search {
          @include icon(search, $color-white, 24px, 24px);
        }

        &.language {
          @include icon(globe, $color-white, 24px, 24px);
        }

        &.find {
          @include icon(spray, $color-white, 24px, 24px);
        }
      }
    }
  }
}