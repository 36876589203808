// Products block

.block {
    &--markets {
        margin: 0 0 ($default-block-margin - $default-margin);
        overflow: hidden;
    }
}

.markets {
    display: flex;
    flex-direction: column;

    &__title {
        text-align: center;
    }

    &__slider {
        display: flex;
        flex-direction: column;
    }

    &__buttons {
        text-align: center;
    }
}

.market {
    $self: &;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 0 $default-margin;

    &__image {
        position: relative;
        overflow: hidden;

        @include media-breakpoint-down(sm) {
            max-height: 420px;
        }

        &__hover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: all ease-in-out .3s;
        }

        img {
            @include media-breakpoint-down(xs) {
                object-fit: contain;
            }
        }
    }

    &:hover {
        #{ $self }__image {
            &__hover {
                opacity: 1;
            }
        }
    }

    &__content {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
    }
}

.markets-slider {
    display: flex;
    flex-direction: row;

    &__item {
        flex: 0 0 33.33%;
        max-width: 33.33%;
        padding: 0 10px;

        @include media-breakpoint-down(md) {
            flex: 0 0 50%;
            max-width: 50%;
        }

        @include media-breakpoint-down(sm) {
            flex: 0 0 75%;
            max-width: 75%;
        }
    }
}

.swiper {
    &--markets-slider {
        overflow: initial;
    }
  .swiper-navigation {
    position: unset;
    padding-right: unset;
    left: unset;
  }
}
