*,
*::before,
*::after {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-kerning: auto;
}

html,
body {
    font-family: $text-font;
    color: $text-color;
    font-weight: 400;
    font-size: $text-size;
    line-height: $line-height;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    background: $color-yellow;
}

html {
    &.modal-active {
        overflow: hidden;
    }
}

body {
    display: flex;
    flex-direction: column;
    position: relative;
}

img:not([src]) {
    visibility: hidden;
}

img[data-src],
img[data-srcset] {
    display: block;
    min-height: 1px;
}

.search-highlight {
    background-color: $color-white;
    color: $color-black;
}

.date {
    font-weight: 300;
    font-size: 15px;
}

iframe {
    display: block;
    max-width: 100%;
    border: 0;
}

a,
.link {
    // color: $color-red;
    color: $color-white;
    outline: none;
    text-decoration: none;

    &:hover,
    &:focus {
        // color: $color-red;
        color: $color-white;
        outline: none;
        // text-decoration: underline;
        text-decoration: none;
        opacity: 0.8;
    }
}

p a:hover, p a:focus {
    text-decoration: underline;
}

.btn {
    color: $color-white;

    &:hover,
    &:focus {
        color: $color-white;
    }
}

a[href^="tel"] {
    color: inherit;
    text-decoration: none;
}

.date-label {
    background: $color-black;
    color: $color-white;
    font-size: 14px;
    line-height: 14px;
    font-weight: 700;
    text-align: left;
    padding: 5px 10px;
}

p,
blockquote,
ul,
ol,
h1,
h2,
h3,
h4,
h5,
h6,
form,
table,
section,
aside,
article,
dl,
hr {
    margin: 0 0 $default-margin;
}

table {
    width: 100%;
    border-collapse: collapse;
}

ul,
ol {
    padding: 0;
    list-style-type: none;
}

img {
    height: auto;
    max-width: 100%;

    &[loading] {
        width: auto;
    }
}

.video-wrapper {
    text-align: center;
    margin: 0 0 $default-margin;

    &__inner {
        position: relative;
        display: block;
        padding: 25px 0 56.25%;
        height: 0;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

.is-video {
    display: block;
    position: relative;

    &::before {
        @include icon(play, $color-white, 60px, 60px);
        position: absolute;
        top: 50%;
        left: 50%;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        content: '';
        transform: translate(-50%, -50%);
        z-index: 1;
    }

    &:hover,
    &:focus {
        &::before {
            @include icon(play, $color-blue, 60px, 60px);
        }
    }
}

picture,
figure {
    display: block;
    margin: 0;
}

section {
    &.main {
        position: relative;
        flex: 1 0 auto;
        margin: 0;
    }
}

.wrapper {
    @extend .container;
    @include clearfix();
    padding-left: 30px;
    padding-right: 30px;

    @include media-breakpoint-down(sm) {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.info-toggle {
    width: 30px;
    height: 30px;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    line-height: 30px;
    color: $color-black;
    background: $color-white;
    border-radius: 50%;
}

.modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba($color-black, .9);
    visibility: hidden;
    opacity: 0;
    z-index: -1;
    transition: opacity .3s ease-in-out;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
        color: $color-white;
        max-height: 100vh;
        overflow: auto;
        width: 100%;
        padding: 80px 0;
        z-index: 0;

        @include media-breakpoint-down(md) {
            max-height: 90vh;
            margin-top: 10vh;
            padding: 0;
        }

        @include media-breakpoint-down(sm) {
            max-height: 85vh;
            margin-top: 15vh;
        }

        .is-h2,
        .is-h3,
        .is-h4,
        .is-h5 {
            color: $color-white;
        }
    }

    .btn {
        &--close {
            position: absolute;
            right: 40px;
            top: 40px;
            z-index: 1;

            @include media-breakpoint-down(sm) {
                right: 20px;
                top: 20px;
            }
        }
    }
}

[data-modal-container] {
    display: none;

    &.active {
        display: block;

        .modal {
            visibility: visible;
            opacity: 1;
            z-index: 999;
        }

        .icon {
            display: none;
        }
    }
}

[v-cloak] {
  display: none;
}

.block {
    h1, .is-h1,
    h2, .is-h2,
    h3, .is-h3,
    h4, .is-h4 {
        overflow-wrap: break-word;
    }
}
