/*
 * The Typekit service used to deliver this font or fonts for use on websites
 * is provided by Adobe and is subject to these Terms of Use
 * http://www.adobe.com/products/eulas/tou_typekit. For font license
 * information, see the list below.
 *
 * arboria:
 *   - http://typekit.com/eulas/00000000000000003b9b0118
 *   - http://typekit.com/eulas/00000000000000003b9b0119
 *   - http://typekit.com/eulas/00000000000000003b9b011a
 *   - http://typekit.com/eulas/00000000000000003b9b011b
 *   - http://typekit.com/eulas/00000000000000003b9b011c
 *   - http://typekit.com/eulas/00000000000000003b9b011d
 *   - http://typekit.com/eulas/00000000000000003b9b011e
 *   - http://typekit.com/eulas/00000000000000003b9b011f
 *   - http://typekit.com/eulas/00000000000000003b9b0120
 *   - http://typekit.com/eulas/00000000000000003b9b0121
 *   - http://typekit.com/eulas/00000000000000003b9b0122
 *   - http://typekit.com/eulas/00000000000000003b9b0123
 *
 * © 2009-2023 Adobe Systems Incorporated. All Rights Reserved.
 */
/*{"last_published":"2023-03-13 11:12:25 UTC"}*/

@import url("https://p.typekit.net/p.css?s=1&k=zvd5mtc&ht=tk&f=34858.34859.34860.34861.34862.34863.34864.34865.34866.34867.34868.34869&a=3619837&app=typekit&e=css");

@font-face {
    font-family:"arboria";
    src:url("https://use.typekit.net/af/451d48/00000000000000003b9b0118/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3") format("woff2"),url("https://use.typekit.net/af/451d48/00000000000000003b9b0118/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3") format("woff"),url("https://use.typekit.net/af/451d48/00000000000000003b9b0118/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i1&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:100;font-stretch:normal;
}

@font-face {
    font-family:"arboria";
    src:url("https://use.typekit.net/af/8eae95/00000000000000003b9b0119/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff2"),url("https://use.typekit.net/af/8eae95/00000000000000003b9b0119/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("woff"),url("https://use.typekit.net/af/8eae95/00000000000000003b9b0119/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n1&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:100;font-stretch:normal;
}

@font-face {
    font-family:"arboria";
    src:url("https://use.typekit.net/af/8ad5fe/00000000000000003b9b011a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff2"),url("https://use.typekit.net/af/8ad5fe/00000000000000003b9b011a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("woff"),url("https://use.typekit.net/af/8ad5fe/00000000000000003b9b011a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
    font-family:"arboria";
    src:url("https://use.typekit.net/af/a712db/00000000000000003b9b011b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff2"),url("https://use.typekit.net/af/a712db/00000000000000003b9b011b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("woff"),url("https://use.typekit.net/af/a712db/00000000000000003b9b011b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i3&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:300;font-stretch:normal;
}

@font-face {
    font-family:"arboria";
    src:url("https://use.typekit.net/af/2ed003/00000000000000003b9b011c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/2ed003/00000000000000003b9b011c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/2ed003/00000000000000003b9b011c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
    font-family:"arboria";
    src:url("https://use.typekit.net/af/f350ae/00000000000000003b9b011d/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/f350ae/00000000000000003b9b011d/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/f350ae/00000000000000003b9b011d/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
    font-family:"arboria";
    src:url("https://use.typekit.net/af/69ac60/00000000000000003b9b011e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/69ac60/00000000000000003b9b011e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/69ac60/00000000000000003b9b011e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:500;font-stretch:normal;
}

@font-face {
    font-family:"arboria";
    src:url("https://use.typekit.net/af/f89c37/00000000000000003b9b011f/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff2"),url("https://use.typekit.net/af/f89c37/00000000000000003b9b011f/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("woff"),url("https://use.typekit.net/af/f89c37/00000000000000003b9b011f/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i5&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:500;font-stretch:normal;
}

@font-face {
        font-family:"arboria";
    src:url("https://use.typekit.net/af/7c0289/00000000000000003b9b0120/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/7c0289/00000000000000003b9b0120/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/7c0289/00000000000000003b9b0120/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
    font-family:"arboria";
    src:url("https://use.typekit.net/af/1f8fa9/00000000000000003b9b0121/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/1f8fa9/00000000000000003b9b0121/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/1f8fa9/00000000000000003b9b0121/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
    font-family:"arboria";
    src:url("https://use.typekit.net/af/1ad443/00000000000000003b9b0122/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/1ad443/00000000000000003b9b0122/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/1ad443/00000000000000003b9b0122/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3") format("opentype");
    font-display:auto;font-style:normal;font-weight:900;font-stretch:normal;
}

@font-face {
    font-family:"arboria";
    src:url("https://use.typekit.net/af/fce9bd/00000000000000003b9b0123/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff2"),url("https://use.typekit.net/af/fce9bd/00000000000000003b9b0123/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("woff"),url("https://use.typekit.net/af/fce9bd/00000000000000003b9b0123/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i9&v=3") format("opentype");
    font-display:auto;font-style:italic;font-weight:900;font-stretch:normal;
}

.tk-arboria { font-family: "arboria", sans-serif; }