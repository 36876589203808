$img-url: '/assets/img/dc/';

.surface {
  color: $color-gray-dark2;

  &__text {
    font-weight: 600;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .tooltip {
        margin-left: auto;
    }
  }

  &__content {
    display: grid;
    grid-column-gap: 12px;
  }

  &__item {
    display: flex;
    align-items: center;

    &-icon {
      width: 30px;
      height: 30px;
      margin-right: 5px;
      background-size: 30px;

      &.wood {
        background-image: url($img-url + 'icon-wood.png');
      }

      &.metal {
        background-image: url($img-url + 'icon-metal.png');
      }

      &.stone {
        background-image: url($img-url + 'icon-stone.png');
      }

      &.plastic {
        background-image: url($img-url + 'icon-plastic.png');
      }

      &.glas,
      &.glass {
        background-image: url($img-url + 'icon-glass.png');
      }

      &.styrofoam {
        background-image: url($img-url + 'icon-styrofoam.png');
      }

      &.textiles {
        background-image: url($img-url + 'icon-textiles.png');
      }

      &.universal {
        background-image: url($img-url + 'icon-universal.png');
      }
    }

    span {
      flex: 1;
    }

    &.disabled {
      opacity: .3;
    }

    &.selectable {
      cursor: pointer;

      &:hover {
        color: #79006C;
      }
    }
  }
}