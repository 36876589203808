.navigation {
    $self: &;

    ul {
        margin: 0;

        li {
            display: block;
            padding: 0;
            margin: 0;

            a {
                display: block;
                color: $text-color;
                padding: 6px 5px;

                @include media-breakpoint-down(md) {
                    padding: 6px 0;
                }

                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }

            > ul {
                display: none;
            }

            &:hover,
            &:focus {
                > a {
                    color: $color-blue;
                    text-decoration: none;
                }
            }

            &.active {
                > a {
                    color: $color-blue;
                }

                > ul {
                    display: block;
                }
            }
        }
    }

    &--header {
        margin-left: 25px;

        @include media-breakpoint-down(lg) {
            margin-left: 5px;
        }

        @include media-breakpoint-down(md) {
            background-color: $color-white;
            position: absolute;
            left: calc(100% + 40px);
            top: 0;
            width: 100%;
            padding-top: 80px;
            border-radius: 30px;
            margin: 0;
            transition: left .3s;
        }

        ul {
            display: flex;
            padding: 0;
            margin: 0;
            list-style: none;
            font-weight: 600;

            @include media-breakpoint-down(md) {
                display: block;
                padding: 0 40px 40px;
            }

            li + li {
                margin-left: 10px;

                @include media-breakpoint-down(lg) {
                    margin-left: 0;
                }

                @include media-breakpoint-down(md) {
                    margin: 20px 0 0;
                }
            }

            li {
                a {
                    color: #141215;
                    transition: color .3s;

                    &:hover {
                        color: $color-blue;
                    }
                }
            }
        }
    }

    &--footer {
        margin-right: 20px;

        ul {
            display: flex;
            flex-wrap: wrap;

            li {
                display: inline-block;
                margin: 0 6px;

                a {
                    color: $text-color;
                    font-size: 14px;
                    line-height: 28px;
                    letter-spacing: 0;
                    text-transform: uppercase;
                    padding: 0;
                    transition: color .3s;
                }
            }
        }
    }

    &--anchor {
        margin: 0;

        ul {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 -5px;

            li {
                display: inline-block;
                margin: 0 30px;

                a {
                    color: $color-white;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                    text-transform: none;
                    text-decoration: none;
                    position: relative;
                    padding: 8px 10px 18px;

                    &::before {
                        content: '';
                        width: 0;
                        height: 4px;
                        background: $color-white;
                        position: absolute;
                        left: 50%;
                        bottom: 0;
                        transition: all ease .3s;
                        opacity: 0;
                    }
                }

                &:hover,
                &:focus {
                    > a {
                        color: $color-white;
                        text-decoration: none;

                        &::before {
                            opacity: 1;
                            width: 100%;
                            left: 0;
                        }
                    }
                }

                &.active {
                    > a {
                        color: $color-white;

                        &::before {
                            opacity: 1;
                            width: 100%;
                            left: 0;
                        }
                    }
                }
            }
        }
    }
}

.primary-navigation-active {
    .navigation {
        &--header {
            @include media-breakpoint-down(md) {
                left: 0;
            }
        }
    }
}

.primary-search-active {
    .navigation {
        &--header {
            @include media-breakpoint-down(md) {
                top: 79px;
            }
        }
    }
}
