.download {
  .btn {
    width: 100%;
    font-weight: 400;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;

    em {
      font-style: normal;
      font-size: 12px;
      width: 36px;
      padding-left: 10px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 14px;
      width: 21px;
      height: 21px;
      transform: translateY(-50%);
    }

    &--white {
      color: #141215;

      &::after {
        @include icon(download, $color-purple-dark, 21px, 21px);
      }
    }

    &--pink,
    &--blue {
      &::after {
        @include icon(download, $color-white, 21px, 21px);
      }
    }
  }
}