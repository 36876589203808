.block {
  &--product-summary {
    position: relative;
    overflow: hidden;
    padding: 120px 0 20px;

    &::before {
      content: "";
      position: absolute;
      left: 50%;
      top: -50%;
      width: 50%;
      height: 110%;
      background-color: $color-orange;
      transform: rotate(-15deg);

      @include media-breakpoint-down(md) {
        left: 45%;
        width: 60%;
        height: 100%;
      }
    }
  }
}

.product-summary {
  display: flex;
  position: relative;

  @include media-breakpoint-down(md) {
    display: block;
  }

  &__gallery {
    width: calc(52% + 58px);
    min-width: 0;
    padding-right: 58px;

    @include media-breakpoint-down(md) {
      width: 100%;
      padding: 0;
      margin-bottom: 20px;
    }
  }

  &__content {
    width: 48%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .is-h3 {
      margin-bottom: 13px;
      text-transform: none;
    }

    .richtext {
      margin-bottom: 60px;
    }
  }

  &__params {
    .custom-select--colors {
      overflow: unset;
      color: $body-color;

      &.dark {
        color: $color-white;
        .custom-select__icon {
          @include icon(colors, $color-white, 23px, 21px);
        }
      }

      .bootstrap-select {
        width: 100% !important;
      }

      .dropdown-toggle {
        padding: 0 10px 0 50px;
        background-color: transparent;
        color: inherit;

        &:focus {
          outline: none !important;
        }

        &.disabled {
          cursor: default;

          &::after {
            display: none;
          }
        }
      }

      .dropdown-menu {
        margin: 0;
        padding: 0;
        max-height: min(300px, 80vh);
        box-shadow: 2px 2px 10px $color-gray;

        .dropdown-item {
          padding: 0.25rem 0.5rem;

          &.dark {
            color: $color-white;
          }

          &.light {
            color: $color-black;
          }

          &.disabled {
            display: none;
          }

          &.active {
            background-color: transparent;
            font-weight: bold;
          }
        }
      }
    }

    &-wrap {
      margin-bottom: 24px;
    }

    .full-width {
      grid-column: 1 / -1;
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: auto;
    }
  }

  &__surface {
    background: $color-white;
    padding: 20px;
    border-radius: 20px;

    .environment {
      margin: -7px 0 7px;
    }

    .surface {
      &__content {
        grid-template-columns: repeat(4, 1fr);
        grid-row-gap: 20px;

        @include media-breakpoint-down(sm) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }

  &__btn {
    margin-top: 20px;

    .btn {
      min-width: 264px;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  .form-product-detail {
    position: relative;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;

    &.loading {
      opacity: .6;

      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url('/assets/img/loading@2x.gif') center center no-repeat;
      }
    }
  }
}

.environment {
  font-weight: 500;
  color: $color-gray-dark;
  justify-content: space-between;
  align-items: center;
  padding-left: 50px;
  cursor: default;

  &:hover {
    color: $color-gray-dark;
  }

  &__icon {
    @include icon(indutdoor, $color-black, 32px, 22px);
    position: absolute;
    width: 50px;
    top: 0;
    left: 0;
    height: 100%;
  }

  &__text {
    margin-right: auto;
  }
}