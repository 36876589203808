.block {
  &--characteristics {
    padding: 60px 0 40px;
    display: flex;
    min-height: 780px;
    // background-color: #008fdb;
  }
}

.characteristics {
  max-width: 734px;
  margin: 0 auto;

  &__title {
    text-align: center;
    margin-bottom: 37px;
  }

  .richtext {
    margin-bottom: 48px;

    p {
      margin: 0;
      font-weight: 500;
    }
  }
}