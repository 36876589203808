.advantages {
  // color: $color-orange;

  a {
      text-decoration: none;

      &:hover {
          text-decoration: none;
          h1, h2, h3 {
            // color: $color-red;
            opacity: 0.8;
          }
      }

      &:visited {
          text-decoration: none;
      }

      &:link {
          text-decoration: none;
      }
  }

  .is-h1,
  .is-h4 {
    // color: $color-orange;
    margin: 0;
  }

  &__title {
    margin-bottom: 72px;

    @include media-breakpoint-down(md) {
      margin-bottom: 55px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 50px;
      margin-bottom: 30px;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 18px;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-row-gap: 40px;
      grid-column-gap: 8px;
    }

    @include media-breakpoint-down(sm) {
      padding: 0 20px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &-image {
      position: relative;
      margin-bottom: 90px;
      width: 200px;
      height: 200px;

      @include media-breakpoint-down(md) {
        margin-bottom: 40px;
      }

      @include media-breakpoint-down(sm) {
        width: 100px;
        height: 100px;
      }

      img {
        position: relative;
      }
    }
  }

  &__footer {
    text-align: center;
    font-style: italic;
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
  }
}