// Title block
.block {
    &--title {
        padding-top: 140px;
        margin-bottom: 50px;
    }
}

html :not(.fp-enabled) .block--title {
    margin: 0;
    padding-bottom: 40px;
}

.title {
    &__title {
        text-align: center;

        .is-h1,
        .is-h2 {
            font-weight: 900;
        }
    }

    &__content {
        text-align: center;

        .richtext {
            font-size: 24px;
            line-height: 42px;
        }
    }
}
