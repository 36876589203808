.find-product {
  &__title {
    margin-bottom: 46px;
    color: $color-white;

    @include media-breakpoint-down(md) {
      font-size: 32px;
      line-height: 36px;
    }
  }

  &__content {
    display: grid;
    grid-gap: 18px;
  }

  &--col & {
    &__content {
      grid-template-columns: repeat(4, 1fr);

      @include media-breakpoint-down(md) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
      }

      @include media-breakpoint-down(sm) {
        grid-template-columns: auto;
        grid-gap: 20px;
      }
    }
  }
}