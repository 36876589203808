.shop-list {
  padding-top: 60px;
  background-color: $color-purple;
  position: fixed;
  top: 0;
  right: -100%;
  height: 100%;
  width: 538px;
  z-index: 200;
  transition: right .7s;

  @include media-breakpoint-down(sm) {
    width: 100%;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &__wrap {
    padding: 0 40px 60px;
    overflow: auto;
    height: 100%;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba($color-pink, .7);
    }
  }

  &__item {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: $color-white;
    padding: 30px 18px;
    border-radius: 50px;
    text-decoration: none;
    color: $color-purple;

    &-logo {
      width: 65px;
      margin-right: 14px;
      text-align: center;
    }

    .btn {
      margin-left: auto;
    }

    & + & {
      margin-top: 20px;
    }

    &:hover {
      color: $color-purple;
      text-decoration: none;
    }
  }

  &.active {
    right: 0;
  }
}