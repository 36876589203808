.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    &__content {
        padding: 20px;
        display: flex;
        justify-content: space-between;
    }

    .logo {
        display: block;
        position: relative;
        z-index: 2;

        img {
            display: block;
        }
    }

    &__menu {
        position: relative;
        display: flex;
        align-items: center;
        background-color: $color-white;
        height: 40px;
        border-radius: 20px;
        padding: 0 30px 0 15px;
        box-shadow: 0 0 10px #0000005A;

        @include media-breakpoint-down(lg) {
            padding: 0 15px;
        }

        @include media-breakpoint-down(md) {
            height: 60px;
            width: 100%;
            border-radius: 30px;
            justify-content: center;
            padding: 0;
        }
    }

    &__btns {
        display: flex;
        gap: 10px;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    .nav-toggle {
        position: absolute;
        top: 0;
        left: 0;
        display: none;
        width: 60px;
        height: 60px;
        align-items: center;
        justify-content: center;
        padding: 0;
        z-index: 5;

        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
        }

        span,
        &::before,
        &::after {
            top: 0;
            position: relative;
            display: block;
            width: 20px;
            height: 2px;
            margin: 3px auto;
            content: '';
            background-color: $color-purple;
            border-radius: 2px;
            backface-visibility: hidden;
        }

        span {
            transition: opacity .3s;
        }

        &::before,
        &::after {
            transition: top .3s .3s, transform .3s 0s;
        }

        &.active {
            span {
                opacity: 0;
            }

            &::before,
            &::after {
                transition: top .3s 0s, transform .3s .3s;
            }

            &::before {
                top: 8px;
                transform: rotate(45deg);
            }

            &::after {
                top: -8px;
                transform: rotate(-45deg);
            }
        }
    }

    .btn {
        &.search {
            @include icon(search, $color-white, 20px, 20px);
        }

        &.language {
            @include icon(globe, $color-white, 20px, 20px);
        }
    }
}