.download-info {
  &__row {
   & +& {
     margin-top: 20px;
   }
  }

  &__title {
    font-weight: 700;
    margin-bottom: 4px;
  }
}