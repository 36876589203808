.block {
  &--instruction {
    padding: 70px 0 40px;
    display: flex;
    min-height: 780px;
    background-color: #008fdb;
  }
}

.instruction {
  &__title {
    margin-bottom: 72px;
    overflow: hidden;

    .is-h2 {
      @include media-breakpoint-down(md) {
        font-size: 72px;
        line-height: 76px;
      }

      @include media-breakpoint-down(sm) {
        font-size: 58px;
        line-height: 62px;
        word-break: break-all;
      }

      @include media-breakpoint-down(xs) {
        font-size: 44px;
        line-height: 56px;
        word-break: break-all;
      }
    }
  }

  &__list {
    counter-reset: counter;
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 46px;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__item {
    counter-increment: counter;
    display: flex;
    position: relative;

    @include media-breakpoint-down(md) {
      display: block;
      text-align: center;
    }

    &::before {
      content: counter(counter);
      background-color: $color-white;
      position: absolute;
      top: -30px;
      left: 90px;
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      font-size: 32px;
      line-height: 54px;
      color: $color-blue-dark;
      font-weight: 700;
      border-radius: 50%;

      @include media-breakpoint-down(md) {
        left: 50%;
        margin-left: 30px;
      }
    }

    &-image {
      width: 120px;
      height: 120px;
      margin-right: 35px;

      @include media-breakpoint-down(md) {
        margin: 0 auto;
      }
    }

    &-text {
      padding-top: 16px;
      flex: 1;
    }
  }
}
