.filters {
  color: $color-gray-dark2;

  @include media-breakpoint-down(md) {
    background-color: $color-yellow;
    position: fixed;
    top: 0;
    left: -120%;
    width: 100%;
    height: 100%;
    z-index: 200;
    overflow: auto;
    transition: left .3s;
  }

  &__body {
    @include media-breakpoint-down(md) {
      padding: 20px 60px 40px;
    }
  }

  &__header {
    margin-bottom: 24px;
    display: none;

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &__title {
    color: $color-white;
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
  }

  &__content {
    display: grid;
    grid-gap: 18px;
  }

  &__close {
    top: 20px;
    right: 40px;
    position: absolute;
  }

  &__panel {
    position: sticky;
    padding: 0 40px;
    bottom: 30px;
    z-index: 10;
    display: none;

    @include media-breakpoint-down(md) {
      display: flex;
    }

    .btn + .btn {
      margin-left: 20px;
    }
  }

  &__wrap {
    background-color: #fff;
    border-radius: 20px;
    padding: 20px 30px 30px;
  }

  &__item {
    margin-left: 9px;
    line-height: 15px;

    & + & {
      margin-top: 9px;
    }
  }

  &__footer {
    padding-top: 36px;
  }

  &__result {
    font-size: 16px;
    line-height: 26px;
    font-weight: 700;
    text-align: center;
    color: #79006C;
  }

  &__reset {
    padding-top: 20px;
    display: flex;
    justify-content: center;
  }

  .form-group {
    > label {
      text-transform: none;
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
      margin-bottom: 25px;
    }

    & + .form-group {
      margin: 40px 0 0;
    }
  }

  .form-control-wrapper {
    margin-left: 10px;
  }

  &.active {
    left: 0;
  }

  &.active & {
    &__panel {
      display: flex;
    }
  }
}