// Lightcase override

a[class*='lightcase-icon-'] {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

//scss-lint:disable IdSelector
#lightcase-case {
    font-family: $text-font;
    font-weight: 400;
    font-size: $text-size;
    line-height: $line-height;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $color-black;
    }
}
//scss-lint:enable IdSelector
